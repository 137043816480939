import React, { useState, useEffect } from "react";
import "../../../styles/main.scss";

import IndexPage from "../../index";

const WeAreMotion = (props) => {
  useEffect(() => {}, []);
  return <IndexPage params={props.params} />;
};

export default WeAreMotion;
